import React, { useEffect, useState } from "react";
import * as Button from "../../components/Buttons";
import InputData from "../../components/InputData";
import SearchHeader from "../../components/SearchHeader";
import InputSearch from "../../components/SearchHeader/inputSeach";
import HarvestDemand from "../../components/TableHarvest/TableHarvestDemand";
import HarvestRequested from "../../components/TableHarvest/TableHarvestRequested";
import HarvestHarvested from "../../components/TableHarvest/TableHavestHavested";
import TopMenu from "../../components/TopMenu";

//import css
import "../CommonStyle/style.css";
import styles from "./Harvest.module.css";

//tabs table imports
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import * as locales from "@mui/material/locale";

//material ul imports
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import HarvestForecast from "../../components/TableHarvest/TableHarvestForecast";
import { captalizeFirstLetterEacheWord, getDateWithoutTimezone } from "../../utils/utils";
import { HarvestService } from "../../service/harvest_service";
import { UserService } from "../../service/user_service";
import { CropSeasonService } from "../../service/crop_season_service";
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const stylesItem = {
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",

	p: 0,
};
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 0, width: "100%" }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function Harvest() {
	const [search, setSearch] = useState(""); //search
	const [value, setValue] = React.useState(0);
	const [demanda, setDemanda] = React.useState([]);
	const [colheitasRealizada, setColheitasRealizada] = React.useState([]);
	const [colheitasSolicitada, setColheitasSolicitada] = React.useState([]);
	const [colheitasPrevista, setColheitasPrevista] = React.useState([]);
	const [columnsHarvestRequested, setColumnsHarvestRequested] = useState([
		{
			id: "cuture",
			label: "Cultura",
		},
		{
			id: "quant",
			label: "Quantidade",
		},
		{
			id: "unid",
			label: "Unidade de Medida",
		},
		{
			id: "operador",
			label: "Operador",
		},
		{
			id: "data_solicitacao",
			label: "Data de solicitação",
		},
		{
			id: "data_realizacao",
			label: "Data de realização",
		},
	]);
	const [columnsHarvestHarvested, setColumnsHarvestHarvested] = useState([
		{
			id: "cuture",
			label: "Cultura",
		},
		{
			id: "quant",
			label: "Quantidade",
		},
		{
			id: "unid",
			label: "Unidade de Medida",
		},
		{
			id: "lote",
			label: "Lote",
		},
		{
			id: "data_solicitacao",
			label: "Data de solicitação",
		},
		{
			id: "data_realizacao",
			label: "Data de realização",
		},		
	]);
	const [selectedDate, setSelectedDate] = useState("");
	const [user, setUser] = useState("");

	const harvestService = new HarvestService();
	const userService = new UserService();
	const cropSeasonService = new CropSeasonService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		const propertyId = localStorage.getItem("propertyId");
		setUser(user.isAdmin);

		const baseFilters = {
			page: 1,
			limit: 100000,
		};
		const demandParams = {
			...baseFilters,
			filterValue: encodeURI(JSON.stringify({ pendente: true, previsao: false, responsavelColheita: user.id })),
			orderBy: JSON.stringify(
				{
					createdAt: "desc"
				}
			)
		};

		const demands = await harvestService.getByProperty(propertyId, demandParams);
		setDemanda(getDemandaDetails(demands));
		if (user.isAdmin) {
			const finishedParams = {
				...baseFilters,
				filterValue: encodeURI(JSON.stringify({pendente: false, previsao: false})),
				orderBy: JSON.stringify(
					{
						createdAt: {
							cultura: "desc"
						}
					}
				)
			};
			let finished = await harvestService.getByProperty(propertyId, finishedParams);
			setColheitasRealizada(await getColheitasRealizadasDetails(finished, user.isAdmin));
			const solicitedParams = { 
				...baseFilters,
				filterValue: encodeURI(JSON.stringify({previsao: false})),
				orderBy: JSON.stringify(
					{
						createdAt: {
							cultura: "desc"
						}
					}
				)
			};
			let solicited = await harvestService.getByProperty(propertyId, solicitedParams);
			setColheitasSolicitada(await getSolicitadasDetails(solicited, user.admin));
		} else {
			const finishedParams = {
				...baseFilters,
				filterValue: encodeURI(JSON.stringify({pendente: false, previsao: false, responsavelColheita: user.id})),
				orderBy: JSON.stringify(
					{
						createdAt: {
							cultura: "desc"
						}
					}
				)
			};
			let finished = await harvestService.getByProperty(propertyId, finishedParams);
			setColheitasRealizada(await getColheitasRealizadasDetails(finished, user.isAdmin));
			const solicitedParams = {
				...baseFilters,
				filterValue: encodeURI(JSON.stringify({previsao: false, solicitanteColheita: user.id})),
				orderBy: JSON.stringify(
					{
						createdAt: {
							cultura: "desc"
						}
					}
				)
			};
			let solicited = await harvestService.getByProperty(propertyId, solicitedParams);
			setColheitasSolicitada(await getSolicitadasDetails(solicited, user.admin));
		}

		const forecastParams = {
			...baseFilters,
			filterValue: encodeURI(JSON.stringify({previsao: true, solicitanteColheita: user.id})),
			orderBy: JSON.stringify(
				{
					createdAt: "desc"
				}
			)
		};
		let forecast = await harvestService.getByProperty(propertyId, forecastParams);
		setColheitasPrevista(await getForecastDetails(forecast, user.admin));
	};

	const getDemandaDetails = (lotes) => {
		const lotesDetails = [];
		for (let i = 0; i < lotes.length; i++) {
			const lote = lotes[i];
			const Propertycultivo = lote.propriedadeCultivo;
			const embalagem = lote.embalagem;
			const unidade = embalagem.unidadeMedida;
			const cultivo = Propertycultivo.cultivo;
			let newColheita = {
				id: lote.id,
				itemId: lote.id,
				cuture: captalizeFirstLetterEacheWord(`${cultivo.cultura} ${(cultivo.variedade ?? "")}`),
				quant: lote.quantidade,
				unid: unidade.sigla,
				id_cuture: lote.propriedadeCultivoId,
				data_solicitacao: formatDate(lote.dataColheita),
			};
			lotesDetails.push(newColheita);
		}
		return lotesDetails;
	};

	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	const getColheitasRealizadasDetails = async (lotes, userAdmin) => {
		const lotesDetails = [];
		for (let i = 0; i < lotes.length; i++) {
			const lote = lotes[i];
			const safrasFromLote = lote.loteSafras;
			let quantidade = 0;
			for (let j = 0; j < safrasFromLote.length; j++) {
				const safra = safrasFromLote[j];
				quantidade += safra.quantidade ? safra.quantidade * 1000 : 0;
			}
			quantidade /= 1000;
			const Propertycultivo = lote.propriedadeCultivo;
			const embalagem = lote.embalagem;
			const unidade = embalagem.unidadeMedida;
			const usuario = lote.responsavelColheita !== null && lote.responsavelColheita !== undefined ? await userService.getById(lote.responsavelColheita) : null;
			const cultivo = Propertycultivo.cultivo;
			let newColheita = {
				id: lote.id,
				itemId: lote.id,
				cuture: captalizeFirstLetterEacheWord(`${cultivo.cultura} ${(cultivo.variedade ?? "")}`),
				quant: quantidade,
				unid: unidade.sigla,
				id_cuture: lote.propriedadecultivoID,
				lote: lote.codigo,
				operador: usuario !== null && usuario !== undefined? usuario.nome : "Não informado",
				data_solicitacao: formatDate(lote.dataColheita),
				data_realizacao: formatDate(lote.dataColheita),
			};
			lotesDetails.push(newColheita);
		}

		if (userAdmin) {
			setColumnsHarvestHarvested([
				{
					id: "cuture",
					label: "Cultura",
				},
				{
					id: "quant",
					label: "Quantidade",
				},
				{
					id: "unid",
					label: "Unidade de Medida",
				},
				{
					id: "lote",
					label: "Lote",
				},
				{
					id: "data_solicitacao",
					label: "Data de solicitação",
				},
				{
					id: "data_realizacao",
					label: "Data de realização",
				},
				{
					id: "operador",
					label: "Operador",
				},
			]);
		} else {
			setColumnsHarvestHarvested([
				{
					id: "cuture",
					label: "Cultura",
				},
				{
					id: "quant",
					label: "Quantidade",
				},
				{
					id: "unid",
					label: "Unidade de Medida",
				},
				{
					id: "lote",
					label: "Lote",
				},
				{
					id: "data_solicitacao",
					label: "Data de solicitação",
				},
				{
					id: "data_realizacao",
					label: "Data de realização",
				},		
			]);
		}
		return lotesDetails;
	};

	const getSolicitadasDetails = async (lotes, userAdmin) => {
		const lotesDetails = [];
		for (let i = 0; i < lotes.length; i++) {
			const lote = lotes[i];
			let quantidade = 0;
			if (lote.pendente) {
				quantidade = lote.quantidade;
			} else {
				const safrasFromLote = lote.loteSafras;
				for (let j = 0; j < safrasFromLote.length; j++) {
					const safra = safrasFromLote[j];
					quantidade += safra.quantidade * 1000;
				}
				quantidade /= 1000;
			}
			const Propertycultivo = lote.propriedadeCultivo;
			const embalagem = lote.embalagem;
			const unidade = embalagem.unidadeMedida;
			const usuario = lote.responsavelColheita !== null && lote.responsavelColheita !== undefined ? await userService.getById(lote.responsavelColheita) : null;
			const cultivo = Propertycultivo.cultivo;
			const solicitante = await userService.getById(lote.solicitanteColheita);

			let newColheita = {
				id: lote.id,
				itemId: lote.id,
				cuture: captalizeFirstLetterEacheWord(`${cultivo.cultura} ${(cultivo.variedade ?? "")}`),
				quant: quantidade,
				unid: unidade.sigla,
				id_cuture: lote.propriedadecultivoID,
				operador:
					usuario !== null && usuario !== undefined
						? usuario.nome
						: "não definido",
				data_solicitacao: formatDate(lote.dataColheita),
				data_realizacao: formatDate(lote.dataColheita),
				solicitante: solicitante?.nome,
			};
			lotesDetails.push(newColheita);
		}
		if (userAdmin) {
			setColumnsHarvestRequested([
				{
					id: "cuture",
					label: "Cultura",
				},
				{
					id: "quant",
					label: "Quantidade",
				},
				{
					id: "unid",
					label: "Unidade de Medida",
				},
				{
					id: "operador",
					label: "Operador",
				},
				{
					id: "data_solicitacao",
					label: "Data de solicitação",
				},
				{
					id: "data_realizacao",
					label: "Data de realização",
				},
				{
					id: "solicitante",
					label: "Solicitante",
				},
			]);
		} else {
			setColumnsHarvestRequested([
				{
					id: "cuture",
					label: "Cultura",
				},
				{
					id: "quant",
					label: "Quantidade",
				},
				{
					id: "unid",
					label: "Unidade de Medida",
				},
				{
					id: "operador",
					label: "Operador",
				},
				{
					id: "data_solicitacao",
					label: "Data de solicitação",
				},
				{
					id: "data_realizacao",
					label: "Data de realização",
				},
			]);
		}
		return lotesDetails;
	};

	const getForecastDetails = async (lotes) => {
		const lotesDetails = [];
		for (let i = 0; i < lotes.length; i++) {
			const lote = lotes[i];
			const loteSafras = lote.loteSafras;
			const loteSafra = loteSafras[0];
			const safraname = await getSafraName(loteSafra.safraId);
			const date = getDateWithoutTimezone(lote.dataPrevista);
			const dateString =
				("0" + date.getUTCDate()).slice(-2) +
				"/" +
				("0" + (date.getUTCMonth() + 1)).slice(-2) +
				"/" +
				date.getUTCFullYear();
			
			let newColheita = {
				id: lote.id,
				itemId: loteSafra.safraId,
				loteId: lote.id,
				data: dateString,
				safra: safraname,
			};
			lotesDetails.push(newColheita);
		}
		return lotesDetails;
	};

	const getSafraName = async (safraId) => {
		const safra = await cropSeasonService.getById(safraId);
		const talhao = safra.talhao;
		let safraname = `Talhão ${talhao.nome}`;
		if (safra.subdivisaoId !== null && safra.subdivisaoId !== undefined) {
			const subdivisao = safra.subdivisao;
			const subdivisaoCategory = subdivisao.categoriaSubdivisao;
			safraname += ` - ${captalizeFirstLetterEacheWord(subdivisaoCategory)} ${subdivisao.complemento}`;
		
		}
		const propertyCulture = safra.propriedadeCultivo;
		const cultivo = propertyCulture.cultivo;
		safraname += ` - ${captalizeFirstLetterEacheWord(cultivo.cultura)} ${captalizeFirstLetterEacheWord(cultivo.variedade ?? "")}`;
		return safraname;
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	function handleClick(e) {
		setSearch(e.target.value); //seta o valor do campo de busca
	}

	const theme = useTheme();

	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales["ptBR"]),
		["ptBR", theme]
	);
	const handleDelete = async (id) => {
		await harvestService.delete(id);
		initData();
	};

	const handleDateChange = (date) => {
		setSelectedDate(new Date(date).toLocaleDateString("pt-BR"));
	};
	return (
		<>
			<TopMenu open={["launch", "harvest"]} help="harvest" />
			<Box component="main" sx={{ p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 0, md: 0 }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								<SearchHeader
									title="Colheitas"
									href="/colheita/cadastroColheita"
									textButton="Nova Colheita "
									extraButton={
										<Button.GenericButtonGreen
											href="/colheita/previssaoColheita"
											text={"Previsao de Colheita"}
										/>
									}
								>
									<InputSearch func={handleClick} />
								</SearchHeader>
							</Item>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "#fff",
									border: "none",
									boxShadow: "none",
									pt: 3,
									p: 1,
									m: 0,
								}}
							>
								<ThemeProvider theme={themeWithLocale}>
									<Grid
										container
										rowSpacing={0}
										columnSpacing={{ xs: 0, sm: 2, md: 3 }}
										sx={{bgColor:"white"}}
									>
										<Grid item xs={12} sm={12} md={6}>
											<Item sx={stylesItem}>
												<Tabs
													value={value}
													onChange={handleChange}
													textColor="white"

													// className="tabs"
												>
													<Tab
														className={
															value === 0
																? styles.tab + " " + styles.focuz
																: styles.tab
														}
														label="Demanda"
														{...a11yProps(0)}
														sx={{
															fontSize: { xs: 9, sm: 10, md: 14 },
															width: "25%",
														}}
													/>
													<Tab
														className={
															value === 1
																? styles.tab + " " + styles.focuz
																: styles.tab
														}
														label="Realizadas "
														{...a11yProps(1)}
														sx={{
															fontSize: { xs: 9, sm: 10, md: 14 },
															width: "25%",
														}}
													/>
													<Tab
														className={
															value === 2
																? styles.tab + " " + styles.focuz
																: styles.tab
														}
														label="Solicitadas "
														{...a11yProps(2)}
														sx={{
															fontSize: { xs: 9, sm: 10, md: 14 },
															width: "25%",
														}}
													/>
													<Tab
														className={
															value === 3
																? styles.tab + " " + styles.focuz
																: styles.tab
														}
														label="Previsões"
														{...a11yProps(3)}
														sx={{
															fontSize: { xs: 9, sm: 10, md: 14 },
															width: "25%",
														}}
													/>
												</Tabs>												
											</Item>
											
										</Grid>
										<Grid item xs={12} sm={12} md={6}>
											<Item sx={stylesItem}>
												{user === true && <div className={styles.btnGoto}>
													<div className={styles.btnContainer}>
														<InputData placeholder="Data" onDateChange={handleDateChange} value={selectedDate} classN={styles.btnContainerInputDate} withPortal={true} />													
														<a href={`pdf/?pag=Colheita&startDate=${selectedDate}`} className={styles.btnGoToPdf}>Demanda para Colheita</a>
													</div>
												</div>}
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<Item sx={stylesItem}>
												<TabPanel
													className={styles.tabPanel}
													value={value}
													index={0}
												>
													<HarvestDemand
														data={demanda}
														search={search}
														handleDelete={handleDelete}
													/>
												</TabPanel>
												<TabPanel
													className={styles.tabPanel}
													value={value}
													index={1}
												>
													<HarvestHarvested
														data={colheitasRealizada}
														search={search}
														columns={columnsHarvestHarvested}
														handleDelete={handleDelete}
													/>
												</TabPanel>
												<TabPanel
													className={styles.tabPanel}
													value={value}
													index={2}
												>
													<HarvestRequested
														data={colheitasSolicitada}
														search={search}
														columns={columnsHarvestRequested}
														handleDelete={handleDelete}
													/>
												</TabPanel>
												<TabPanel
													className={styles.tabPanel}
													value={value}
													index={3}
												>
													<HarvestForecast
														data={colheitasPrevista}
														search={search}
														handleDelete={handleDelete}
													/>
												</TabPanel>
											</Item>
										</Grid>
									</Grid>
								</ThemeProvider>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	);
}

export default Harvest;
